<template>
  <Layout tituloPagina="Sistema | Ajustes | Clientes">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Ajustes de clientes
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="actualizar()"
                  v-if="!bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  Actualizar
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  Actualizar
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="$router.go(-1)"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3 mb-3">
            <label>Nuevo número de cliente</label>
            <input
                type="number"
                min="0"
                v-model="ajustes.nuevo_numero_cliente" 
                class="form-control text-right"/>
            <small class="m-l-sm">Es el siguiente numero que el sistema asigna automaticamente</small>
          </div>

          <div class="col-md-3">
            <label>Agregar foto a los clientes</label>
            <div class="form-check form-switch form-switch-lg">
              <input
                class="form-check-input"
                type="checkbox"
                id="fotoCliente"
                :checked="ajustes.foto_cliente"
                @change="
                  ajustes.foto_cliente = !ajustes.foto_cliente
                "
              />
              <label
                class="form-check-label"
                for="fotoCliente"
              >
                {{ ajustes.foto_cliente ? 'Mostrar' : 'No mostrar' }}
              </label>
            </div>
          </div>
        </div>
        <br />
        <hr>
        <h3>Campos Alias</h3>
        <div class="row">
          <div class="col-md-7">
            <div class="table-responsive">
              <table class="tblRouters table table-hover table-nowrap">
                <thead>
                  <tr>
                    <th>Visible</th>
                    <th>Campo</th>
                    <th>Alias</th>
                    <th class="text-center" style="width:150px;">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(campo,index) in campos_alias" :key="`campo-${index+1}`">
                    <td>
                      <div class="form-check form-switch form-switch-md">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :id="'switch-' + index"
                          :checked="campo.visible"
                          @change="
                            campo.visible = !campo.visible,
                            actualizarCampoAlias(campo)
                          "
                        />
                        <label
                          class="form-check-label"
                          :for="'switch-' + index"
                        ></label>
                      </div>
                    </td>
                    <td>{{ campo.campo }}</td>
                    <td>
                      <input
                        type="text"
                        style="min-width: 250px;"
                        v-model="campo.alias"
                        :placeholder="`Alias de (${campo.campo})`"
                        class="form-control"
                      />
                    </td>
                    <td class="text-right">
                      <button class="btn btn-outline-info btn-sm" v-if="campo.activar_opciones" @click="opcionesAlias(campo)">
                        <i class="mdi mdi-clipboard-list"></i>
                        Opciones
                      </button>
                      <button
                        class="btn btn-outline-success btn-sm"
                        title="Actualizar"
                        @click="actualizarCampoAlias(campo,index,$event)"
                      >
                        <i class="mdi mdi-content-save"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <mdl-opciones
          ref="mdl_opciones"
          v-on:refrescar-alias="refrescarAlias"
        />
      </div>
    </div>

    <div class="text-right pb-4">
      <div class="btn-group">
        <button class="btn btn-secondary"
          @click="$router.go(-1)">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button class="btn btn-success" @click="actualizar()" :disabled="bandera_spinner">
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          Actualizar
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import VistasCampos from '@/services/VistasCampos.js'
import MdlOpciones from './MdlOpciones.vue'
import SistemaSrv from '@/services/SistemaSrv.js'

import { todoMethods } from "@/state/helpers";
export default {
  name: 'Clientes',
  components: { Layout, MdlOpciones },
  data() {
    return {
      ajustes: {
        nuevo_numero_cliente: null,
        foto_cliente:false
      },
      campos_alias:[
        {
          id:null,
          campo:'Nombre',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'Apellido paterno',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'Apellido materno',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'RFC',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'Sexo',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'Tipo de cliente',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'Estado',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'Nota',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'Calle',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: true
        },
        {
          id:null,
          campo:'N° ext',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: true
        },
        {
          id:null,
          campo:'N° int',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: true
        },
        {
          id:null,
          campo:'Colonia',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: true
        },
        {
          id:null,
          campo:'Delegación o Municipio',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: true
        },
        {
          id:null,
          campo:'Código postal',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: true
        },
        {
          id:null,
          campo:'Estado de residencia',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: true
        },
        {
          id:null,
          campo:'País',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: true
        },
        {
          id:null,
          campo:'Correo electrónico',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'Teléfonos',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'Panel de cliente',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'Messenger',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'URL 1',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'URL 2',
          alias: '',
          visible: true,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'Dato auxiliar 1',
          alias: '',
          visible: false,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'Dato auxiliar 2',
          alias: '',
          visible: false,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'Dato auxiliar 3',
          alias: '',
          visible: false,
          opciones: '',
          activar_opciones: false
        },
        {
          id:null,
          campo:'Dato auxiliar 4',
          alias: '',
          visible: false,
          opciones: '',
          activar_opciones: false
        }
      ],
      bandera_spinner: false
    }
  },

  created: function(){
    var self = this
    
    self.cargarAliasClientes()
    self.refrescarAjustes()
  },
  methods:{
    ...todoMethods,
    actualizar() {
      let self = this,
        ajustes = Object.assign({}, self.ajustes)
      
      self.bandera_spinner = true

      SistemaSrv.actualizar(ajustes).then(response => {
        iu.msg.success('Datos actualizados')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron actualizar los datos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    actualizarCampoAlias: function(campo) {
      var self = this

      if(campo.id == null){
        var nuevo_campo = Object.assign({
                            id_vista: 36
                          },campo)
        
        VistasCampos.guardar(nuevo_campo).then(response => {
          iu.msg.success('Sé registro correctamente el alias "'+campo.campo+'"')
          self.refrescarAlias()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo guardar el alias'
          }
          iu.msg.error(mensaje)
        })
      } else {
        VistasCampos.actualizar(campo).then(response => {
          iu.msg.success('Se actualiza correctamente el alias "'+campo.campo+'"')
          self.refrescarAlias()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo guardar el alias'
          }
          iu.msg.error(mensaje)
        })
      }
    },

    cargarAliasClientes:function(){
      var self = this
      var campos = self.$store.state.todo.alias

      self.campos_alias.forEach(camp => {
        let alias = campos.find(ali => {
          return ali.campo == camp.campo
        })

        if(alias != null) {
          Object.assign(camp,{
            id: alias.id,
            campo: alias.campo,
            alias: camp.alias != '' ? camp.alias : alias.alias,
            visible: alias.visible,
            opciones: alias.opciones,
            created_at: alias.created_at,
            updated_at: alias.updated_at
          })
        }
      });
    },

    opcionesAlias: function(campo) {
      var self = this

      self.$refs.mdl_opciones.mostrar(campo)
    },

    refrescarAlias:function(){
      var self = this

      VistasCampos.vistasCamposJSON().then(response => {
        self.cargarAlias({ alias: response.data })
        self.cargarAliasClientes()
      })
    },

    refrescarAjustes() {
      let self = this
      
      SistemaSrv.ajustes(['nuevo_numero_cliente','foto_cliente']).then(response => {
        self.ajustes.nuevo_numero_cliente = response.data.nuevo_numero_cliente
        self.ajustes.foto_cliente = response.data.foto_cliente == "0" ? false : true
      })
    }
  }
}
</script>

<style scoped>
.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}
</style>